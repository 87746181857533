import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Image from 'next/legacy/image'
import Onboard1 from '@/assets/img/partnership-modal/onboard-1.png'
import Onboard1Mobile from '@/assets/img/partnership-modal/onboard-1-mobile.png'
import UlyssePartnership from '@/assets/img/partnership-modal/ulysse-partnership.png'
import Button from '@/components/ui/button'
import Trans from '@/components/i18n/Trans'
import useTranslation from '@/hooks/translation'
import useAnalytics from '@/hooks/analytics'

function Step1({ partnership: partnership }) {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title
        as="h3"
        className="title-1 text-30 leading-[30px] md:text-40 md:leading-10 mb-20"
      >
        <Trans
          i18nKey="components.partnershipModal.step1.title"
          components={{
            br: <br className="block md:hidden" />,
          }}
        />
      </Dialog.Title>
      <p className="mt-10 text-18 md:text-22 leading-[22px] md:leading-7 text-dark-80">
        {t('components.partnershipModal.step1.welcome', {
          prefixFr: partnership.prefix_fr || '',
          partnershipName: partnership.name,
        })}
      </p>
      <ul className="list-disc list-outside pl-20">
        <li className="mt-15 text-14 md:text-[17px] md:leading-[25px] text-dark-80">
          <Trans
            i18nKey="components.partnershipModal.step1.numerOneInEurope"
            components={{
              a: (
                <a
                  className="text-primary"
                  href="https://fr.trustpilot.com/review/ulysse.com"
                />
              ),
            }}
          />
        </li>
        <li className="mt-15 text-14 md:text-[17px] md:leading-[25px]  text-dark-80">
          {t('components.partnershipModal.step1.basedInFrance')}
        </li>
        <li className="mt-15 text-14 md:text-[17px] md:leading-[25px] text-dark-80">
          {t('components.partnershipModal.step1.accompaniedTravelers')}
        </li>
      </ul>
    </>
  )
}

export default function PartnershipModal({
  partnership,
  mode,
  deletePartnership,
}) {
  const maxStep = 1
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(true)
  const nextStepButtonRef = useRef(null)
  const { t } = useTranslation()
  const { track } = useAnalytics()

  const steps = {
    1: {
      component: <Step1 partnership={partnership} />,
      image: Onboard1,
      imageMobile: Onboard1Mobile,
      nextStepButtonText: t('components.partnershipModal.letsGo'),
    },
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={nextStepButtonRef}
        onClose={() => {
          setOpen(true)
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-50 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full justify-center p-0 text-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`w-[335px] ${
                  mode === 'welcome' ? `md:w-[824px]` : `md:w-[624px]`
                } h-fit max-h-[570px] flex flex-col md:flex-row transform rounded-xl bg-white text-left shadow-xl transition-all relative`}
              >
                {mode === 'welcome' ? (
                  <>
                    <div
                      className="absolute text-white cursor-pointer right-[10px] top-[10px] z-30 drop-shadow-sm"
                      onClick={() => {
                        track('partnership_modal_welcome_close', {
                          partnership,
                        })
                        setOpen(false)
                      }}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 15 15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM6.58076 7.51826L4.6875 5.625L5.60674 4.70576L7.5 6.59902L9.39326 4.70576L10.3125 5.625L8.41924 7.51826L10.2942 9.39326L9.375 10.3125L7.5 8.4375L5.625 10.3125L4.70576 9.39326L6.58076 7.51826Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>

                    <div className="block md:hidden flex justify-between h-[150px] bg-[#6681B2]">
                      <div className="pt-20 z-10 grow relative right-[-30px]">
                        <div className="relative h-[60px]">
                          <Image
                            className=""
                            src={UlyssePartnership}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>

                        <div className="relative mt-10 mb-5 h-[33px]">
                          <Image
                            className=""
                            src={partnership.pop_in_image_url}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                      </div>
                      <Image
                        src={steps[step].imageMobile}
                        loading="eager"
                        width={180}
                        alt=""
                      />
                    </div>
                    <div className="grow md:w-[435px] p-[30px] md:p-60 flex flex-col gap-20 rounded-xl justify-between z-30 max-h-[430px] md:max-h-fit -mt-10 md:mt-0 bg-white">
                      <div className="grow overflow-auto">
                        {steps[step].component}
                      </div>
                      <div className="flex gap-20 items-center">
                        <Button
                          className="min-w-[175px] w-full md:w-auto"
                          trackingEventName={
                            step < maxStep
                              ? 'partnership_modal_welcome_next'
                              : 'partnership_modal_welcome_close'
                          }
                          color="primary"
                          onClick={() => {
                            if (step < maxStep) {
                              setStep(step + 1)
                            } else {
                              setOpen(false)
                            }
                          }}
                          ref={nextStepButtonRef}
                        >
                          {steps[step].nextStepButtonText}
                        </Button>
                      </div>
                    </div>
                    <div
                      className="hidden md:flex flex-col w-[389px] h-[570px] relative"
                      style={{
                        background: '#6681B2',
                      }}
                    >
                      <div className="mt-60 h-[119px]">
                        <div className="relative h-[60px]">
                          <Image
                            className=""
                            src={UlyssePartnership}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>

                        <div className="relative mt-10 mb-5 h-[43px]">
                          <Image
                            className=""
                            src={partnership.pop_in_image_url}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                      </div>
                      <Image
                        src={steps[step].image}
                        loading="eager"
                        alt=""
                        layout="responsive"
                        objectPosition="bottom"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="absolute text-white cursor-pointer right-[10px] top-[10px] z-50 drop-shadow-sm"
                      onClick={() => setOpen(false)}
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 15 15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM6.58076 7.51826L4.6875 5.625L5.60674 4.70576L7.5 6.59902L9.39326 4.70576L10.3125 5.625L8.41924 7.51826L10.2942 9.39326L9.375 10.3125L7.5 8.4375L5.625 10.3125L4.70576 9.39326L6.58076 7.51826Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div className="grow w-fit md:w-[335px] flex flex-col justify-between items-center z-30 max-h-[470px] -mt-10 md:mt-0 rounded-xl border-none bg-[#6681B2]">
                      <div
                        className="w-full p-[15px] md:p-30 rounded-t-xl z-10"
                        style={{
                          background: '#6681B2',
                        }}
                      >
                        <div className="relative h-[60px] mb-10">
                          <Image
                            className=""
                            src={UlyssePartnership}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                        <div className="relative h-[40px]">
                          <Image
                            className=""
                            src={partnership.pop_in_image_url}
                            alt=""
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                      </div>
                      <div className="bg-white rounded-b-xl py-30">
                        <div className="w-full overflow-auto px-30 md:px-60">
                          <p className="text-16 mb-15 md:text-18 text-center text-dark-80">
                            <Trans
                              i18nKey="components.partnershipModal.returning.exclusiveResults"
                              components={{
                                b: <strong />,
                              }}
                              values={{ partnershipName: partnership.name }}
                            />
                          </p>
                        </div>
                        <div className="flex px-30 md:px-60 gap-20 mt-30 flex-col md:flex-row justify-center items-center">
                          <Button
                            className="min-w-[175px] w-full md:w-auto"
                            outline
                            color="primary"
                            onClick={() => {
                              deletePartnership()
                              setOpen(false)
                            }}
                            ref={nextStepButtonRef}
                            trackingEventName="partnership_modal_returning_back"
                          >
                            {t(
                              'components.partnershipModal.returning.cta.backToUlysse'
                            )}
                          </Button>
                          <Button
                            className="min-w-[175px] w-full md:w-auto"
                            color="primary"
                            trackingEventName="partnership_modal_returning_continue"
                            onClick={() => {
                              setOpen(false)
                            }}
                            ref={nextStepButtonRef}
                          >
                            {t(
                              'components.partnershipModal.returning.cta.continueWithPartnership',
                              {
                                partnershipName: partnership.name,
                              }
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
