import { useRouter } from 'next/router'
import { DARK_PAGES } from '@/components/constants'

// Components
import Navigation from '@/components/Navigation'
import FooterComponent from '@/components/Footer'

function DefaultContentLayout({ children }) {
  const { route } = useRouter()
  const isDark = DARK_PAGES.some((P) => P === route)
  return (
    <div className="bg-white">
      <Navigation isDark={isDark} />
      {children}
      <FooterComponent sticky={false} />
    </div>
  )
}

export default DefaultContentLayout
